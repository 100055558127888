<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="ABL ESD Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="ABL ESD Machine Description"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/ABLESDDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ABLESDDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing electrostatic discharge (ESD)
              sensitivity tests is to measure the sensitiveness of a material to
              ESD. The transition from no reaction to reaction must be
              determined to evaluate in-process risks. The in-process potential
              from esd scenarios is compared against the material response. For
              example below is shown a plot of an example transition from
              no-reaction at low ESD energies to reaction at increasing
              energies. The ESD energy is used to compare in-process scenarios
              to the material response.
            </p>
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/scurve-esd-min.png')"
            >
              <img src="@/assets/IPSeries/scurve-esd-min.png" />
            </a>
            <TitleComponent class="pt-8" title="Equipment" size="medium" />
            <p class="p-light">
              The ABL ESD machine has been used by Hercules, ATK, SMS, and
              others to closely mimic in-process conditions and obtain reaction
              probabilities at various ESD energies for more than 25 years.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th class="px-4 py-2">Key Parameter</th>
                    <th class="px-4 py-2">Objectives</th>
                    <th class="px-4 py-2">Origin</th>
                    <th class="px-4 py-2">Specs</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">Supply voltage</td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a calibrated high-voltage discharge pulse
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Approximate a human or machine high-voltage discharge
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 5000 VDC ± 1% (adjustable)
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      System capacitance
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a calibrated high-voltage discharge pulse: actual
                      discharge capacitance should be determined
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Approximate a human or machine discharge
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized bank of capacitors from 0.0001 μF up to 0.5
                      μF
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Approaching needle
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Simulate discharge from a charged, approaching object
                      through energetic material to a grounded object
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Approaches the sample holder
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Shape of discharge electrode
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a energy discharge consistent with that of an
                      approaching needle
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as a needle-shaped electrode (phonograph
                      needle)
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Gap between needle and sample holder
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Sufficient gap to ensure the sample is not impacted by the
                      needle; close enough that the needle will discharge
                      through the gap
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Typical gap between needle and sample holder is between
                      0.020 in and 0.060 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample thickness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a test surface through which a spark can pass
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other sample thicknesses may be used to simulate specific
                      process equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to a monolayer of material; thickness of
                      slices are typically 0.033 ± 0.004 in
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Sample quantity
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a uniform area so that the pathway of least
                      resistance is through the test sample (not around it)
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium"></td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Sample holder</td>
                    <td class="border px-4 py-2 font-medium">
                      Conductive and large enough to prevent edge effects
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 0.5-in diameter
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">Firing circuit</td>
                    <td class="border px-4 py-2 font-medium">
                      <ul>
                        <li>
                          Provide personnel safety from high voltage and energy
                          discharge by grounding the discharge electrode when
                          the access door is open
                        </li>
                        <li>
                          Provide a circuit that does not significantly alter or
                          deplete the discharge energy
                        </li>
                        <li>
                          Provide a grounding path representative of in-process
                          conditions
                        </li>
                      </ul>
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium"></td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Reaction detection
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Observation, detection, and documentation of a material’s
                      response to the test stimuli
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      High-speed video, GoDetect, gas analyzer
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Characterization of reaction types
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Flash/ flame, audible report, smoke, flame trace,
                      significant sample consumption, decomposition (change in
                      color or odor), jetting
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Test Temperature and Humidity
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as ambient temperature and humidity.
                      In-process conditions should be used as possible.
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Adequately determine the sensitivity result
                      (characterization curve) with the fewest number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      At least 30 trials
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample condition
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Repeatably obtain results for a given sample
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Substances are tested in the worst-case or
                      smallest-particle-size in-process condition.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Reaction upon insult
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Detection of explosion (high-speed video with GoDetect™,
                      gas analysis, etc.)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      The trial is considered “+” at the ONSET of material
                      reaction.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability most efficiently defined by use of
                      an algorithm such as SEQ, Neyer, or other step sized
                      optimized method
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Risk is assessed based on the comparison of the in-process
                      energies to the material response.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="ABL ESD Setup Example"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/setupesd-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/setupesd-min.png" />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Flash (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166117496?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166117496"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of No Sample Propagation (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166117497?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166117497"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Flame (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166117495?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166117495"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
